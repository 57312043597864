import Prismic from 'prismic-javascript';
import { SectionType } from '../types/SectionType';

type ContentImageSpec = {
    url:string;
};

type ContentLinkSpec = {
    label:string;
    destination_url:string;
}

type ContentButtonSpec = ContentLinkSpec & { display_inverse:boolean; }

type ContentImage = {
    url:string;
    alt:string;
    small:ContentImageSpec;
    medium:ContentImageSpec;
}

type SectionContentTextBlock =
{
    slice_type: 'text_block';
    primary: {
        block_image:ContentImage;
        block_image_small_size_width:string;
        block_image_small_size_height:string;
        block_image_normal_size_width:string;
        block_image_normal_size_height:string;
    }
    items:{paragraph:string}[];
}

type SectionContentDetailBlock = {
    slice_type: 'detail_block';
    items:{paragraph:string}[];
}

type SectionContentInlineImage = {
    slice_type: 'inline_image',
    primary: { image:ContentImage; }
}

type SectionContentLinkBanner = {
    slice_type: 'link_banner',
    primary: {
        banner_title:string;
        image:ContentImage;
        destination_link: { url:string; }
        strapline:string;
    }
}

export type SectionContentData = {
    id:SectionType;
    title:string;
    section_image:ContentImage;
    body:(SectionContentTextBlock | SectionContentDetailBlock | SectionContentLinkBanner | SectionContentInlineImage)[];
    footer_buttons:ContentButtonSpec[];
};

export type SiteContentData = {
    site_title:string;
    meta_description:string;
    keywords:string;
    instagram_url:string;
    site_build_credit:string;
    site_build_url:string;
    site_design_credit:string;
    site_design_url:string;
    youtube_url:string;
};

export type SectionContentResult = {
    type: 'section';
    data:SectionContentData;
};

export type SiteContentResult = {
    type: 'site';
    data:SiteContentData;
};

export type ContentResult = SectionContentResult | SiteContentResult;

export type ContentData = {
    results:ContentResult[];
}

export default async function getAppContent():Promise<ContentData> {

    const api =
        await Prismic.getApi(
            'https://deangleeson.cdn.prismic.io/api/v2',
            // eslint-disable-next-line max-len
            {accessToken: 'MC5ZRk9lQlJJQUFDSUFfSTZ6.Cu-_vWTvv71aP--_vWTvv709Ye-_vS7vv71GCu-_ve-_ve-_ve-_vXjvv71GL--_ve-_vW_vv70G77-9Hu-_vQ'}
        );

    const response = await api.query('');
    return response as any;

}
