import React, { useEffect, useState } from 'react';
import DocumentMeta from 'react-document-meta';
import MainComponent from './components/MainComponent';
import OverlayComponent from './components/OverlayComponent';
import { AppStateContext } from './context/AppStateContext';
import getAppContent, { ContentData } from './core/appContent';
import { getSiteContent } from './core/appContentUtils';
import './styles/main.scss';

function App() {

    const [content, setContent] = useState<ContentData>();
    const [selectedSectionID, setSelectedSectionID] = useState<string | null>(null);

    useEffect(() => {
        (async () => setContent(await getAppContent()))();
    }, []);

    const siteContent = content? getSiteContent(content.results) : null;
    const siteData = siteContent? siteContent.data : null;
    const metaData =
        siteData?
            {
                title: siteData.site_title,
                description: siteData.meta_description,
                meta: {
                    charset: 'utf-8',
                    name: {
                        keywords: siteData.keywords
                    }
                }
            } :
            null;

    return (
        <>
            {
                siteData? <DocumentMeta {...metaData} /> : null
            }
            {
                content?
                    <div className={`app${selectedSectionID? ' in-section' : ''}`}>
                        <AppStateContext.Provider value={{selectedSectionID, setSelectedSectionID}}>
                            <MainComponent content={content} />
                            <OverlayComponent content={content} />
                        </AppStateContext.Provider>
                    </div> :
                    null
            }
        </>
    );

}

export default App;
