import React, { useEffect, useState } from 'react';
import { useAppState } from '../context/AppStateContext';
import { ContentData, SectionContentResult, SiteContentData, SiteContentResult } from '../core/appContent';
import { getSiteContent, getSectionContentResults, getSectionContentResult } from '../core/appContentUtils';
import { sectionTypes } from '../presets/sectionTypes';
import SectionComponent, { DisplayState } from './SectionComponent';
import SiteFooterComponent from './SiteFooterComponent';

type Props = {
    content:ContentData;
}

export default function MainComponent({content}:Props) {

    const {results} = content;
    const siteResult = getSiteContent(results);
    const sectionResults = getSectionContentResults(results);
    const {selectedSectionID, setSelectedSectionID} = useAppState();

    useEffect(() => {

        const onHashChange = () => {
            const {hash} = location;
            setSelectedSectionID(hash? hash.substr(1) : null);
        };

        window.addEventListener('hashchange', onHashChange);

        return () => window.removeEventListener('hashchange', onHashChange);

    }, []);

    return (
        <div className='main-content'>
            <main className={selectedSectionID? `layout-${selectedSectionID}` : ''}>
                {
                    sectionTypes.map((sectionID) => {

                        const section = getSectionContentResult(sectionResults, sectionID);

                        const displayState =
                            sectionID === selectedSectionID?
                                DisplayState.ShowExpanded :
                                (
                                    selectedSectionID?
                                        DisplayState.Hide :
                                        DisplayState.ShowContracted
                                );

                        return section?
                            <SectionComponent
                                content={section.data}
                                key={`section-${sectionID}`}
                                display={displayState}
                            /> :
                            null;
                    })
                }
            </main>
            <footer>
                {
                    siteResult?
                        <SiteFooterComponent content={siteResult.data} /> :
                        null
                }
            </footer>
        </div>
    );

}
