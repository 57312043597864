import React from 'react';
import { SectionContentResult } from '../core/appContent';
import { getSectionContentResult } from '../core/appContentUtils';
import { sectionTypes } from '../presets/sectionTypes';

type Props = {
    sections:SectionContentResult[];
    onSectionSelect:(id:string) => void;
    selectedSectionID?:string | null;
}

export default function NavigationComponent({sections, onSectionSelect, selectedSectionID}:Props) {
    return (
        <nav>
            {
                sectionTypes
                    .map((id) => getSectionContentResult(sections, id))
                    .map(
                        (section) =>
                            section?
                                <a
                                    href={`#${section.data.id}`}
                                    onClick={() => onSectionSelect(section.data.id)}
                                    key={`nav-item-${section.data.id}`}
                                    className={section.data.id === selectedSectionID? 'selected' : ''}
                                >
                                    {section.data.title}
                                </a> :
                                null
                    )
            }
        </nav>
    );
}
