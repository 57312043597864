import React from 'react';
import { SiteContentData } from '../core/appContent';
import iconInstagram from '../img/icon-instagram.svg';
import iconYouTube from '../img/icon-youtube.svg';

type Props = {
    content:SiteContentData;
}

export default function SiteFooterComponent({content}:Props) {

    const {
        site_design_credit,
        site_design_url,
        site_build_credit,
        site_build_url,
        instagram_url,
        youtube_url
    } = content;

    return (
        <div className='site-footer'>
            <div className='credits'>
                <p>
                    Site design:&nbsp;
                    <a href={site_design_url} target='_blank' rel='noreferrer'>{site_design_credit}</a>
                </p>
                <p>
                    Site build:&nbsp;
                    <a href={site_build_url} target='_blank' rel='noreferrer'>{site_build_credit}</a>
                </p>
            </div>
            <div className='social'>
                <a className='instagram' href={instagram_url} target='_blank' rel='noreferrer'>
                    <img src={iconInstagram} alt='Dean Gleeson on Instagram' />
                </a>
                <a className='youtube' href={youtube_url} target='_blank' rel='noreferrer'>
                    <img src={iconYouTube} alt='Dean Gleeson on YouTube' />
                </a>
            </div>
        </div>
    );
}
