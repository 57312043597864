import React, { useState } from 'react';
import { useAppState } from '../context/AppStateContext';
import { ContentResult } from '../core/appContent';
import { getSectionContentResults, getSiteContent } from '../core/appContentUtils';
import iconClose from '../img/asterisk-solid.svg';
import burger from '../img/bars-regular.svg';
import NavigationComponent from './NavigationComponent';
import SiteFooterComponent from './SiteFooterComponent';

type Props = {
    contentResults:ContentResult[];
}

export default function MenuComponent({contentResults}:Props) {

    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const {selectedSectionID, setSelectedSectionID} = useAppState();

    return (
        <div className='menu'>
            <button className='hamburger' type='button' onClick={() => setShowMobileMenu(true)}>
                <img src={burger} alt='Navigation button' />
            </button>
            <div className='desktop'>
                <NavigationComponent
                    sections={getSectionContentResults(contentResults)}
                    onSectionSelect={(id) => {
                        setShowMobileMenu(false);
                        setSelectedSectionID(id);
                    }}
                    selectedSectionID={selectedSectionID}
                />
            </div>
            <div className={`mobile${showMobileMenu? ' show' : ''}`}>
                <header>
                    <div className='logo' />
                    <button type='button' onClick={() => setShowMobileMenu(false)}>
                        <img className='close' src={iconClose} alt='Menu close control' />
                    </button>
                </header>
                <main>
                    <NavigationComponent
                        sections={getSectionContentResults(contentResults)}
                        onSectionSelect={(id) => {
                            setShowMobileMenu(false);
                            setSelectedSectionID(id);
                        }}
                    />
                </main>
                <footer className='dark-theme'>
                    <SiteFooterComponent content={getSiteContent(contentResults).data} />
                </footer>
            </div>
        </div>
    );
}
