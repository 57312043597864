import React, { useEffect, useState } from 'react';
import { SectionContentData } from '../core/appContent';
import StandardButton from './StandardButton';

export enum DisplayState {
    Hide = 'hide',
    ShowContracted = 'show-contracted',
    ShowExpanded = 'show-expanded'
}

enum ScreenSize {
    NotSet = 'not-set',
    Small = 'small',
    Normal = 'normal'
}

type Props = {
    content:SectionContentData;
    display:DisplayState;
}

export default function SectionComponent({content, display}:Props) {

    const {id, title, section_image, body, footer_buttons} = content;
    const [screenSize, setScreenSize] = useState(ScreenSize.NotSet);

    useEffect(() => {

        const mediaQuery = matchMedia('(min-width: 576px)');

        setScreenSize(mediaQuery.matches? ScreenSize.Normal : ScreenSize.Small);

        const onMediaSizeChange = (e:MediaQueryListEvent) =>
            setScreenSize(e.matches? ScreenSize.Normal : ScreenSize.Small);

        mediaQuery.addEventListener('change', onMediaSizeChange);

        return () => mediaQuery.removeEventListener('change', onMediaSizeChange);

    }, []);

    return (

        <section
            className={
                `${display === DisplayState.Hide? '' : 'show'}${display === DisplayState.ShowExpanded? ' expand' : ''}`
            }
        >

            <header
                className='bg'
                style={{
                    backgroundImage:
                        `url(${screenSize === ScreenSize.Normal? section_image.url : section_image.small.url})`
                }}
            >
                <a href={`#${id}`}>
                    <h1>{title}</h1>
                </a>
            </header>

            <main>
                <div className='primary'>
                    {
                        body
                            .filter(
                                ({slice_type}) =>
                                    ['text_block', 'detail_block', 'link_banner'].indexOf(slice_type) !== -1
                            )
                            .map((bodyItem, bodyItemIndex) => {
                                switch(bodyItem.slice_type) {
                                case 'text_block': {
                                    const {primary, items} = bodyItem;
                                    const {block_image} = primary;
                                    const {url, alt} = block_image;
                                    const {
                                        block_image_small_size_width,
                                        block_image_small_size_height,
                                        block_image_normal_size_width,
                                        block_image_normal_size_height
                                    } = primary;
                                    return (
                                        <div className='text' key={`body-item-${bodyItemIndex}`}>
                                            <div className='content'>
                                                {
                                                    items.map(({paragraph}, paraIndex) => (
                                                        <p key={`para-${paraIndex}`}>
                                                            {paragraph}
                                                        </p>))
                                                }
                                            </div>
                                            <div className='img-container'>
                                                <img
                                                    className='small'
                                                    src={url}
                                                    alt={alt}
                                                    width={`${block_image_small_size_width}px`}
                                                    height={`${block_image_small_size_height}px`}
                                                />
                                                <img
                                                    className='normal'
                                                    src={url}
                                                    alt={alt}
                                                    width={`${block_image_normal_size_width}px`}
                                                    height={`${block_image_normal_size_height}px`}
                                                />
                                            </div>
                                        </div>
                                    );
                                }
                                case 'detail_block': {
                                    const {items} = bodyItem;
                                    return (
                                        <div className='detail' key={`body-item-${bodyItemIndex}`}>
                                            {
                                                items.map(({paragraph}, paraIndex) => (
                                                    <p key={`para-${paraIndex}`}>
                                                        {paragraph}
                                                    </p>))
                                            }
                                        </div>
                                    );
                                }
                                case 'link_banner': {
                                    const {primary} = bodyItem;
                                    const {banner_title, image, destination_link, strapline} = primary;
                                    const {alt, small} = image;
                                    const {url} = small;
                                    return (
                                        <div className='banner' key={`body-item-${bodyItemIndex}`}>
                                            <p className='title'>{banner_title}</p>
                                            <a href={destination_link.url} target='_blank' rel='noreferrer'>
                                                <img src={url} alt={alt} />
                                            </a>
                                            <p className='strapline'>{strapline}</p>
                                        </div>
                                    );
                                }
                                }
                                return null;
                            })
                    }
                </div>
                <div className='secondary'>
                    {
                        body
                            .filter(
                                ({slice_type}) => ['inline_image'].indexOf(slice_type) !== -1
                            )
                            .map((bodyItem, bodyItemIndex) => {
                                switch(bodyItem.slice_type) {
                                case 'inline_image': {
                                    const {primary} = bodyItem;
                                    const {image} = primary;
                                    const {alt} = image;
                                    const url = screenSize === ScreenSize.Normal? image.url : image.small.url;
                                    return <img src={url} alt={alt} key={`body-item-${bodyItemIndex}`} />;
                                }
                                }
                                return null;
                            })
                    }
                    <div className='button-bar'>
                        {
                            footer_buttons.map(
                                ({label, display_inverse, destination_url}) => (
                                    <StandardButton
                                        label={label}
                                        invert={display_inverse}
                                        key={label}
                                        onClick={() => window.open(destination_url)}
                                    />
                                )
                            )
                        }
                    </div>
                </div>
            </main>

        </section>

    );

}
