import { createContext, useContext } from 'react';

export type AppStateContextType = {
    selectedSectionID:string | null;
    setSelectedSectionID:(id:string | null) => void;
}

export const AppStateContext =
    createContext<AppStateContextType>({
        selectedSectionID: null,
        setSelectedSectionID: () => console.error('No app state provider')
    });

export const useAppState = () => useContext(AppStateContext);
