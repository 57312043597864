import React from 'react';
import iconArrow from '../img/icon-arrow.svg';

type Props = {
    label:string;
    invert?:boolean;
    onClick:() => void;
}

export default function StandardButton({label, invert, onClick}:Props) {
    return (
        <button type='button' className={invert? 'invert' : ''} onClick={onClick}>
            <p>{label}</p>
            <img src={iconArrow} alt='' />
        </button>
    );
}
