import React from 'react';
import { ContentData } from '../core/appContent';
import MenuComponent from './MenuComponent';

type Props = {
    content:ContentData;
}

export default function OverlayComponent({content}:Props) {

    return (
        <div className='overlay'>
            <button
                type='button'
                onClick={() => window.location.href='#'}
            >
                <div className='logo' />
            </button>
            <MenuComponent contentResults={content.results} />
        </div>
    );
}
